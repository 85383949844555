import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        border-radius: 5px;
        border: none;
        cursor: pointer;

        .layout-spinner {
            transform: scale(0.5);
        }
    }

    span {
        font-size: 1em;
        text-transform: uppercase;
    }

    /* Layouts */

    &.layout-default {
        .button {
            padding: 0.9em 1.8em;
        }
    }

    &.layout-wide {
        .button {
            padding: 1em 2.6em;
        }
    }

    &.layout-block {
        width: 100%;

        .button {
            padding: 0.7em 1em;
            width: 100%;
        }

        a {
            width: 100%;
        }
    }

    /* Sizes */

    &.size-small {
        .button {
            font-size: 0.75em;
        }
    }

    &.size-medium {
        .button {
            font-size: 0.9em;
        }
    }

    &.size-large {
        .button {
            font-size: 1.15em;
        }
    }

    /* Styles */

    &.style-none {
        .button {
            background-color: transparent;
            padding: 0;
        }
    }

    &.style-primary {
        .button {
            transition: 0.4s ease-in-out color, background-color;

            .button-content {
                font-family: ${vars.fontSecondary};
            }
        }

        &.variant-primary {
            &.gradient-180 {
                .button {
                    background: ${vars.backgroundGradient180};
                }
            }

            &.gradient-250 {
                .button {
                    background: ${vars.backgroundGradient250};
                }
            }

            .button {
                color: #ffffff;
                border: 1px solid ${vars.transparent};
                transition: border-color 200ms ease;
                padding: 0 1em;
                height: 36px;

                .button-content {
                    font-size: 1.1em;
                    font-weight: 400;
                    font-family: ${vars.fontPrimary};
                    text-transform: none;
                }

                &:hover {
                    border-color: ${vars.colorTextWhite};
                }
            }
        }

        &.variant-outline {
            .button {
                background-color: transparent;
                color: ${vars.colorPrimary};
                border: 0.1em solid ${vars.colorPrimary};
                transition: 0.4s ease-in-out color, background-color;

                .button-content {
                    font-weight: 700;
                }

                &:hover {
                    background-color: ${vars.colorPrimary};
                    color: ${vars.colorPrimary};
                }
            }
        }

        &.variant-tertiary {
            .button {
                color: ${vars.colorTextWhite};
                background-color: ${vars.transparent};
                gap: 1em;

                .button-content {
                    font-size: 1.3em;
                    font-weight: 400;
                    font-family: ${vars.fontPrimary};
                    text-transform: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.style-secondary {
        .button {
            transition: 0.4s ease-in-out color, background-color;

            .button-content {
                font-family: ${vars.fontSecondary};
            }
        }

        &.variant-primary {
            .button {
                background-color: ${vars.colorSecondary};
                color: #ffffff;
                border: 0.1em solid ${vars.colorSecondary};
                transition: 0.4s ease-in-out color, 0.2s ease-in-out background-color;

                .button-content {
                    font-weight: 700;
                }

                &:hover {
                    background-color: ${vars.colorPrimary};
                    color: ${vars.colorPrimary};
                }
            }
        }

        &.variant-outline {
            .button {
                background-color: transparent;
                color: ${vars.colorTextLight};
                border: 0.1em solid ${vars.colorSecondary};
                transition: 0.4s ease-in-out color, background-color;

                .button-content {
                    font-weight: 700;
                }

                &:hover {
                    background-color: ${vars.colorSecondary};
                    color: #ffffff;
                }
            }
        }

        &.variant-tertiary {
            .button {
                color: ${vars.colorSecondary};
                background-color: ${vars.transparent};
                gap: 1em;
                padding: 0;

                .button-content {
                    font-size: 0.9em;
                    font-weight: 400;
                    font-family: ${vars.fontPrimary};
                    text-transform: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.style-text-cta {
        justify-content: flex-start;

        .button {
            background-color: transparent;
            padding: 1em 0;

            .button-content {
                font-family: ${vars.fontPrimary};
                font-size: 1.2em;
                font-weight: 600;
                text-decoration: underline;
                text-transform: capitalize;
                color: ${vars.colorViolet};
            }
        }
    }

    /* Modificators */

    &.disabled {
        .button {
            background-color: ${vars.colorDisabled} !important;
            background: ${vars.colorDisabled} !important;
            color: white !important;
            opacity: 0.5;
        }

        pointer-events: none;
    }
`;
