import { FunctionComponent } from 'react';

import { Routes } from 'types/routes';

import FbIcon from 'theme/icons/social/facebook.svg';
import InIcon from 'theme/icons/social/instagram.svg';

import Button from 'components/layout/Button';
import { ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Image from 'components/layout/Image';
import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

// TODO remove mock and replace with translations
const footerTranslations = {
    navigationLinks: [
        { title: 'Kursy', path: Routes.PublicCourses },
        { title: 'Artykuły', path: Routes.PublicBlog },
        { title: 'Kontakt', path: Routes.PublicContact },
        { title: 'Regulamin', path: Routes.PublicRegulations },
        { title: 'Polityka prywatności', path: Routes.PublicPolicyPrivacy },
    ],
};

const WrapperPublicBlocksFooter: FunctionComponent<Props> = () => ((
    <StyledComponent className="wrapper-public-blocks-footer">
        <LayoutContainer>
            <div className="columns">
                <Link href={Routes.PublicHome}>
                    <div className="column-logo">
                        <Logo
                            width={74}
                            height={63}
                        />
                        <div className="logo-title">
                            MIND
                            <br />
                            TEACHER
                        </div>
                    </div>
                </Link>
                <div className="column-nav">
                    <div className="nav-links">
                        {footerTranslations.navigationLinks.map((link) => (
                            <div
                                className="nav-links__link"
                                key={link.path}
                            >
                                <Link
                                    href={link.path}
                                >
                                    <Button
                                        style={ButtonStyles.Primary}
                                        variant={ButtonVariants.Tertiary}
                                    >
                                        {link.title}
                                    </Button>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="cookie-setttings">
                        <button
                            className="cookie-setttings-button"
                            data-cc="c-settings"
                        >
                        Ustawienia plików cookie
                        </button>
                    </div>
                    <div className="social-links">
                        <Link
                            className="social-link"
                            href="https://www.facebook.com/people/Mindteacherpl/100091404870235/?locale=pl_PL"
                        >
                            <Image
                                className="social-link-icon"
                                src={FbIcon}
                                width={35}
                                height={35}
                                layout="fixed"
                            />
                        </Link>
                        <Link
                            className="social-link"
                            href="https://instagram.com/mindteacher.pl?igshid=YmMyMTA2M2Y="
                        >
                            <Image
                                className="social-link-icon"
                                src={InIcon}
                                width={35}
                                height={35}
                                layout="fixed"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </LayoutContainer>
    </StyledComponent>
));

export default WrapperPublicBlocksFooter;
