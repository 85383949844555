import { FunctionComponent, useMemo } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';

import { Props } from './types';

const LayoutSectionsWrapper: FunctionComponent<Props> = ({
    sections,
    memoParams,
    localTranslations,
    enabledStates,
}) => {
    const parsedSections = useMemo(() => {
        const sortedSections = sections.sort((a, b) => a.order - b.order);

        return sortedSections
            .filter(sortedSection => {
                let enabledState = enabledStates[sortedSection.slug];
                enabledState = typeof enabledState === 'boolean'
                    ? enabledState
                    : true;
                return enabledState;
            })
            .filter(sortedSection => sortedSection.isVisible)

            .map((sortedSection, index) => {
                const translations = localTranslations[sortedSection.slug] || {};

                if (index > 2) {
                    const ComponentDelayedHydration = withHydrationOnDemand({ on: ['visible'] })(() => sortedSection.children(sortedSection.slug, translations));
                    return (<ComponentDelayedHydration key={sortedSection.slug} />);
                }

                return sortedSection.children(sortedSection.slug, translations);
            });
    }, memoParams);

    return (
        <>
            {parsedSections}
        </>
    );
};

export default LayoutSectionsWrapper;
