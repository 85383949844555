import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;

    .spinner-text {
        text-align: center;
        font-size: .8em;
        margin: 0 auto;
        margin-top: 1em;
        color: ${vars.colorTextDark};
    }

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }

    .loader {
        font-size: .4em;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.8);
        border-right: 1.1em solid rgba(255, 255, 255, 0.8);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.8);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }


    &.color-primary {
        .loader {
            border-left-color: transparent;
            border-top-color: ${lightenDarkenColor(vars.colorPrimary, 100)};
            border-bottom-color: ${lightenDarkenColor(vars.colorPrimary, 100)};
            border-right-color: ${lightenDarkenColor(vars.colorPrimary, 100)};
        }
    }

    &.color-white {
        .loader {
            border-left-color: white;
            border-top-color: rgba(255, 255, 255, 0.3);
            border-right-color: rgba(255, 255, 255, 0.3);
            border-bottom-color: rgba(255, 255, 255, 0.3);
        }
    }

    &.color-violet {
        .loader {
            border-left-color: ${vars.colorViolet};
            border-top-color: ${lightenDarkenColor(vars.colorViolet, 100)};
            border-bottom-color: ${lightenDarkenColor(vars.colorViolet, 100)};
            border-right-color: ${lightenDarkenColor(vars.colorViolet, 100)};
        }
    }

    &.position-absolute {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        top: 0;
        left: 0;

        .loader-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.position-relative {
        position: relative;

        .spinner-text {
        }

        .spinner {
            position: relative;
        }
    }

    &.size-small {
        font-size: 40%;
    }

    &.size-medium {
        font-size: 100%;
    }

    &.size-large {
        font-size: 160%;
    }
`;

