import { FunctionComponent } from 'react';

import Logo from 'theme/images/logo_mindteacher.svg';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutLogo: FunctionComponent<Props> = ({ altText, width, height }) => ((
    <StyledComponent className="layout-logo">
        <Image
            className="logo-img"
            src={Logo}
            alt={altText || 'Mental Marketplace'}
            width={width || 55}
            height={height || 50}
            layout='fixed'
            objectFit='cover'
        />
    </StyledComponent>
));

export default LayoutLogo;
