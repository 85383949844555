import { FunctionComponent, useContext, useState } from 'react';
import getConfig from 'next/config';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { logout } from 'requests/customers';

import ProfileContext from 'components/context/Profile';
import Button from 'components/layout/Button';
import { ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

// TODO remove mock and replace with translations
const translations = {
    navigationLinks: [
        { title: 'Kursy', path: Routes.PublicCourses },
        { title: 'Mindteachers', path: Routes.PublicCompanies },
        { title: 'Dołącz do nas', path: Routes.PublicJoinUs },
        { title: 'Blog', path: Routes.PublicBlog },
    ],
    redirectToCustomerApp: {
        label: 'Przejdź do panelu klienta',
    },
    ctaButton: {
        label: 'Moje Konto',
    },
    loginClient: {
        label: 'Jestem użytkownikiem',
    },
    loginPartner: {
        label: 'Jestem partnerem',
    },
    logout: {
        label: 'Wyloguj',
    },
};

const WrapperPublicBlocksTopBar: FunctionComponent<Props> = ({ className }) => {
    const profileCtx = useContext(ProfileContext);

    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
    const [visibleLogin, setVisibleLogin]: [boolean, Function] = useState(false);

    const handleDropdownClick = (e: React.MouseEvent<HTMLElement>): void => {
        if (e.target === e.currentTarget || e.target === e.currentTarget.firstChild) {
            setOpenMobileMenu(!openMobileMenu);
        }
    };

    const customerAppPath = () => {
        const { publicRuntimeConfig } = getConfig();
        return publicRuntimeConfig.APP_CUSTOMER_URL;
    };

    const mapNavigationLinks = (link) => (
        <Link
            href={link.path}
            key={link.path}
        >
            <Button
                style={ButtonStyles.Primary}
                variant={ButtonVariants.Tertiary}
            >
                {link.title}
            </Button>
        </Link>
    );


    const clientLoginButtons = (
        <>
            {profileCtx?.profile
                ? (
                    <>
                        <Button
                            className="gradient-180"
                            style={ButtonStyles.Primary}
                            variant={ButtonVariants.Primary}
                            onClick={() => {
                                profileCtx.updateProfile(null);
                                logout().then(() => {
                                    profileCtx.updateProfile(null);
                                });
                            }}
                        >
                            {translations?.logout?.label}
                        </Button>
                        <Link
                            href={customerAppPath()}
                            prefetch={false}
                        >
                            <Button
                                className="gradient-250"
                                style={ButtonStyles.Primary}
                                variant={ButtonVariants.Primary}
                            >
                                {translations?.redirectToCustomerApp?.label}
                            </Button>
                        </Link>
                    </>
                )
                : (
                    <Link
                        href={Routes.PublicCustomerLogin}
                        prefetch={false}
                    >
                        <Button
                            className="gradient-180"
                            style={ButtonStyles.Primary}
                            variant={ButtonVariants.Primary}
                        >
                            {translations?.loginClient?.label}
                        </Button>
                    </Link>
                )
            }
            <Link
                href={Routes.PublicPartnerLogin}
                prefetch={false}
            >
                <Button
                    className="gradient-250"
                    style={ButtonStyles.Primary}
                    variant={ButtonVariants.Primary}
                >
                    {translations?.loginPartner?.label}
                </Button>
            </Link>
        </>
    );

    return (
        <StyledComponent
            id="my-styled-component"
            className={classnames('wrapper-public-blocks-topbar', className)}
        >
            <LayoutContainer
                size={Sizes.Large}
            >
                <div className="columns">
                    <div className="left">
                        <div className="columns__brand">
                            <Link
                                className="logo-link"
                                href={Routes.PublicHome}
                            >
                                <Logo />
                                <h3 className="logo-title">
                                    MINDTEACHER
                                </h3>
                            </Link>
                        </div>
                    </div>
                    <div className="right">
                        <div className="desktop">
                            <nav className="columns__links">
                                {translations?.navigationLinks && translations.navigationLinks.map(mapNavigationLinks)}
                            </nav>
                            <div
                                className="columns__cta"
                                onMouseEnter={() => setVisibleLogin(true)}
                            >
                                <Button
                                    className="gradient-250"
                                    style={ButtonStyles.Primary}
                                    variant={ButtonVariants.Primary}
                                >
                                    {translations?.ctaButton?.label}
                                </Button>
                                {visibleLogin && (
                                    <div
                                        className="columns__cta--choose__login"
                                        onMouseLeave={() => setVisibleLogin(false)}
                                    >
                                        {clientLoginButtons}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mobile">
                            <div
                                className={classnames({
                                    'hamburger-menu': true,
                                    'active': openMobileMenu,
                                })}
                                onClick={handleDropdownClick}
                            >
                                <div
                                    className="stripe"
                                />
                                {openMobileMenu && (
                                    <div className="dropdown">
                                        <div className="navigation-links">
                                            {translations?.navigationLinks && translations.navigationLinks.map(mapNavigationLinks)}
                                        </div>
                                        <div className="client-login-buttons-wrapper">
                                            <details>
                                                <summary>
                                                    {translations?.ctaButton?.label}
                                                </summary>
                                                <div className="buttons-wrapper">
                                                    {clientLoginButtons}
                                                </div>
                                            </details>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksTopBar;
