import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutSpinner: FunctionComponent<Props> = ({ text, color, position, size }) => ((
    <StyledComponent
        className={classnames(
            'layout-spinner',
            'animated',
            'fadeIn',
            [`color-${color}`],
            [`position-${position}`],
            [`size-${size}`]
        )}
    >
        <div className="loader-wrapper">
            <div className="loader" />
            {text ? (
                <p className="spinner-text">
                    {text}
                </p>
            ) : null}
        </div>
    </StyledComponent>
));

export default LayoutSpinner;