import { FunctionComponent } from 'react';

import LayoutCookiesPanel from 'components/layout/CoockiePanel/component';

import Footer from './blocks/Footer';
import Topbar from './blocks/Topbar';
import { Props } from './index';
import StyledComponent from './styles';

const WrapperPublic: FunctionComponent<Props> = ({ children, navigationVisibility }) => {
    return (
        <StyledComponent className="wrapper-public">
            <LayoutCookiesPanel />
            {navigationVisibility?.navigation && <Topbar />}
            <div className="wrapper-content">
                {children}
            </div>
            {navigationVisibility?.footer && <Footer />}
        </StyledComponent>
    );
};

export default WrapperPublic;
