import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    background-color: ${vars.backgroundNavigation};
    padding: 0 0.5em;

    .layout-container {
        min-height: 100px;
        padding: 0 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        .columns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 100px;
            position: absolute;
            padding: 0 .5em;
            background-color: ${vars.backgroundNavigation};

            &__brand {
                .logo-link {
                    .link-tag {
                        display: flex;
                        align-items: center;
                        gap: 1em;

                        .logo-title {
                            font-size: 1.5em;
                            letter-spacing: 8px;
                            color: ${vars.colorTextWhite};
                        }
                    }
                }
            }

            .right {
                display: flex;
                align-items: center;

                .desktop {
                    display: flex;
                    align-items: center;
                }

                .mobile {
                    display: none;
                    color: ${vars.colorTextWhite};

                    .dropdown {
                        z-index: -1;
                        position: fixed;
                        top: 100px;
                        left: 0;
                        min-width: 100vw;
                        height: 100vh;
                        overflow-y: scroll;
                        background-color: ${vars.backgroundMainOpacity90};
                        padding: 0 1em 8em 1em;


                        .client-login-buttons-wrapper {
                            padding-top: 4em;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            details {
                                summary {
                                    clear: both;
                                    background: ${vars.backgroundGradient180};
                                    border-radius: 0.3rem;
                                    font-size: 1.8em;
                                    width: 12em;
                                    padding: .5em;
                                    text-align: center;

                                    &::marker {
                                        display: inline-block;
                                    }
                                }

                                .buttons-wrapper {
                                    display: flex;
                                    margin-left: auto;
                                    margin-right: auto;
                                    left: 0;
                                    right: 0;
                                    flex-direction: column;
                                    gap: 4em;
                                    padding-top: 4em;
                                    padding-bottom: 2em;
                                    font-size: 1.4em;
                                }

                                button {
                                    width: 100%;
                                    padding: 1.7em;
                                }
                            }
                        }

                        .navigation-links {
                            display: flex;
                            flex-direction: column;
                            gap: 2em;
                            padding-top: 1em;

                            button {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 1.8em;
                            }
                        }
                    }

                    .hamburger-menu {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 5em;
                        height: 5em;
                        background-color: #000000;
                        position: relative;
                        border-radius: 60px;
                        cursor: pointer;

                        .dropdown-wrapper {
                            border: 1px solid;
                            border-color: ${vars.backgroundGray};
                            position: absolute;
                            top: 5em;
                            right: 1.5em;
                            padding: 1em 3em 1em 3em;
                            background-color: ${vars.backgroundMain};
                            border-radius: 0.5em;
                            z-index: 1;
                            font-size: 1em;
                        }

                        &.active {
                            .stripe {
                                background-color: transparent;

                                &:before {
                                    top: 0;
                                    transform: rotate(45deg);
                                }

                                &:after {
                                    bottom: 0;
                                    transform: rotate(-45deg);
                                }
                            }
                        }

                        .stripe {
                            position: relative;
                            width: 24px;
                            height: 2px;
                            background-color: #8b62ec;

                            &:before,
                            &:after {
                                left: 0;
                                content: '';
                                position: absolute;
                                height: inherit;
                                width: inherit;
                                transition: transform 100ms ease, top 100ms ease,
                                bottom 100ms ease;
                            }

                            &:before {
                                background-color: #c82ff5;
                                top: -6px;
                            }

                            &:after {
                                background-color: #49a3dd;
                                bottom: -6px;
                            }
                        }
                    }
                }
            }

            &__links {
                display: flex;
            }

            &__cta {
                .layout-button {
                    .button {
                        height: 36px;

                        .button-content {
                            text-transform: none;
                        }
                    }
                }

                &--choose__login {
                    position: absolute;
                    top: 100%;
                    padding: 1em 0;
                    right: -2.5em;
                    width: 16em;
                    min-height: 8em;
                    background-color: ${vars.colorDark};
                    color: ${vars.colorTextWhite};
                    border-radius: 0 0 0.5em 0.5em;

                    display: flex;
                    flex-flow: column wrap;
                    place-content: center;
                    gap: 1em;
                    z-index: 10;

                    .layout-button {
                        .button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.hidden-nav {
        display: none;
    }

    @media all and (max-width: ${vars.tabletL}) {
        padding: 0 0;
        position: relative;

        .layout-container {
            width: 100%;

            .columns {
                padding: 0 1em;
                z-index: 10;
                position: fixed;

                .left {
                    .columns__brand {
                        .logo-link {
                            .link-tag {
                                gap: .5em;
                            }
                        }

                        .logo-title {
                            font-size: 1em !important;
                        }

                        .layout-logo {
                            .logo-img {
                                min-width: 3.6em !important;
                                min-height: 3.2em !important;
                            }
                        }
                    }
                }

                .right {
                    .hamburger-menu {
                        width: 3.3em !important;
                        height: 3.3em !important;
                    }

                    .desktop {
                        display: none;
                    }

                    .mobile {
                        display: flex;

                        .hamburger-menu {
                            .dropdown {
                                .navigation-links {
                                    gap: 1em;
                                }

                                .client-login-buttons-wrapper {
                                    .buttons-wrapper {
                                        gap: 2em;
                                        padding-top: 2em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
