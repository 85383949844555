import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Spinner, { Colors, Positions } from 'components/layout/Spinner';

import StyledComponent from './styles';
import { ButtonIconPositions, ButtonIconTypes, Props } from './types';

const LayoutButton: FunctionComponent<Props> = ({
    children,
    onClick,
    type,
    className,
    icon,
    disabled,
    size,
    style,
    styles,
    variant,
    layout,
    loading,
    buttonProps,
    ariaLabel,
    label,
}) => {
    const getIcon = () => {
        if (!icon) return null;

        if (icon.type === ButtonIconTypes.Element) {
            return icon.value || null;
        }

        if (icon.type === ButtonIconTypes.Image) {
            return (
                <img
                    className="button-icon-image"
                    src={icon.value}
                    loading="lazy"
                    alt={ariaLabel}
                />
            );
        }

        return null;
    };

    const getContent = () => {
        return loading
            ? (
                <Spinner
                    color={Colors.White}
                    position={Positions.Relative}
                />
            )
            : (
                <>
                    {children && (
                        <span
                            className="button-content"
                            dangerouslySetInnerHTML={{ __html: children }}
                        />
                    )}
                    {icon && (
                        <>
                            {icon && icon.position === ButtonIconPositions.Before && (
                                <div className="button-icon-before">
                                    {getIcon()}
                                </div>
                            )}
                            <span className="button-content">
                                {label}
                            </span>
                            {icon && icon.position === ButtonIconPositions.After && (
                                <div className="button-icon-after">
                                    {getIcon()}
                                </div>
                            )}
                        </>
                    )}
                </>
            );
    };

    const getElement = () => (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className="button"
            aria-label={ariaLabel}
            {...buttonProps}
        >
            {getContent()}
        </button>
    );

    return (
        <StyledComponent
            style={styles}
            className={classnames(
                'layout-button',
                className,
                `size-${size}`,
                `style-${style}`,
                `variant-${variant}`,
                `layout-${layout}`,
                { disabled },
            )}
        >
            {getElement()}
        </StyledComponent>
    );
};

export default LayoutButton;
