import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 94%;
    margin: 0 auto;
    position: relative;

    &.size-extra-small {
        max-width: 550px;
    }
    &.size-xxsmall {
        max-width: 800px;
    }
    &.size-xsmall {
        max-width: 950px;
    }
    &.size-small {
        max-width: 1100px;
    }
    &.size-xxmedium {
        max-width: 1140px;
    }
    &.size-xmedium {
        max-width: 1300px;
    }
    &.size-medium {
        max-width: 1366px;
    }
    &.size-large {
        max-width: 1660px;
    }
`;
