import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	padding: 10em 0;
	background-color: ${vars.backgroundNavigation};

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.column-logo {
			position: relative;
			display: flex;
			align-items: center;
			gap: 1em;

			.logo-title {
				font-size: 1.6em;
				line-height: 1.1;
				letter-spacing: 8px;
				color: ${vars.colorTextWhite};
			}
		}

		.column-nav {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: 2em;

			.nav-links {
				display: flex;
				font-size: 1.2em;

				&:last-of-type {
					padding-right: 0;
				}

				&__link {
					&:last-of-type {
						.button {
							padding-right: 0;
						}
					}
				}
			}

			.cookie-setttings {
                margin: 0;
                
				.cookie-setttings-button {
					font-family: ${vars.fontPrimary};

					background-color: transparent;
					border: none;
					cursor: pointer;
					color: ${vars.colorTextWhite};
				}
			}

			.social-links {
				display: flex;
				gap: 1.4em;

				.social-link {
					transition: transform 200ms ease;

					.social-link-icon {
						width: 40px;
						height: 40px;
						padding: 0;

						span {
							width: 100%;
							height: 100%;
						}
					}

					&:hover {
						transform: scale(1.2);
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		padding: 6em 0;

		.columns {
			flex-direction: column;
			gap: 2em;

			.column-nav {
				.nav-links {
					flex-direction: column;

					&__link {
						&:last-of-type {
							.button {
								padding-right: 1.8em;
							}
						}
					}
				}

				.cookie-setttings {
					text-align: center;
                    margin: 0 auto;
				}

				.social-links {
					margin: auto;
				}
			}
		}
	}
`;
