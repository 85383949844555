export enum Routes {
    // Portal routes
    PublicHome = '/',
    PublicPartners = '/partnerzy',
    PublicCourses = '/kursy',
    PublicCourse = '/kursy/[slug]',
    PublicBlog = '/blog',
    PublicJoinUs = '/pakiety',
    PublicAbout = '/o-nas',
    PublicContact = '/kontakt',
    PublicArticles = '/blog/[slug]',
    PublicPolicyPrivacy = '/polityka-prywatnosci',
    PublicRegulations = '/regulamin',
    PublicInquiry = '/ankieta',
    PublicCertificates = '/certyfikaty/',
    PublicCertificate = '/certyfikaty/[id]',
    PublicSurvey = '/ankieta',
    PublicPreviewSurvey = '/podglad-ankiety',
    PublicCompanies = '/mindteachers',
    PublicCompany = '/mindteachers/[slug]',
    PublicLead = '/prowadzacy/[slug]',

    PublicOrderSummary = '/zamowienie/[id]/podsumowanie',
    PublicOrderConfirm = '/zamowienie/[id]/potwierdzenie',

    PublicCustomerRegister = '/klienci/rejestracja',
    PublicCustomerLogin = '/klienci/logowanie',
    PublicCustomerActivate = '/klienci/aktywacja',
    PublicCustomerPasswordReset = '/klienci/przypomnij-haslo',
    PublicCustomerPasswordChange = '/klienci/zmien-haslo',

    PublicPartnerRegister = '/partnerzy/rejestracja',
    PublicPartnerLogin = '/partnerzy/logowanie',
    PublicPartnerActivate = '/partnerzy/aktywacja',
    PublicPartnerPasswordReset = '/partnerzy/przypomnij-haslo',
    PublicPartnerPasswordChange = '/partnerzy/zmien-haslo',

    PublicPdfRegulations = '/pdf/regulamin-2023.pdf',
    PublicPdfPolicyPrivacy = '/pdf/polityka-prywatnosci.pdf',
    PublicPdfPolicyPrivacyUsers = '/pdf/polityka-prywatnosci-uzytkownicy.pdf',

    PublicMailingListConfirmation = '/lista-mailingowa',
    PublicNewsletterStatus = '/newsletter',

    // Partner routes
    PartnerPanel = '/',
}
